import React, { useMemo } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { imageAbsolutePath } from '../../utils/utils';
import { GAME_PLAY_STATUS } from '../../constants';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import { useGetSelectedColorTheme } from '../../hooks/useGetSelectedColorTheme';
import { IRootState } from '../../reducer';
import styles from './Footer.module.css';

const logo = imageAbsolutePath('logo.png');
const footerLogo = imageAbsolutePath('footer-logo.jpg');

// const footerLogo = imageAbsolutePath('footer-logo.svg');
const footerLogoBlackTheme = imageAbsolutePath('footer-logo-black-theme.svg');
// const brandMain = imageAbsolutePath('brand-main.png');
// const brandMainBlackThem = imageAbsolutePath('brand-main-black-them.png');

const footerVariants = {
  show: { opacity: 1, display: 'block' },
  hide: {
    opacity: 0,
    transitionEnd: {
      display: 'none'
    }
  }
};

const Footer: React.FC = () => {
  const { t } = useTranslation();

  const isGameStatusPlay = useSelector(({ game }: IRootState) => game.status) === GAME_PLAY_STATUS ? 'hide' : 'show';

  const { isDarkTheme } = useGetSelectedColorTheme();

  const footerLogoSrc = useMemo(() => (isDarkTheme ? footerLogoBlackTheme : footerLogo), [isDarkTheme]);

  // const brandSrc = useMemo(() => (isDarkTheme ? brandMainBlackThem : brandMain), [isDarkTheme]);

  return (
    <motion.footer animate={isGameStatusPlay} transition={{ duration: 1 }} variants={footerVariants}>
      <Container>
        <Row>
          <Col lg={3}>
            <LinkWrapper className={styles.footerLogo} to="/">
              <p className={styles.underFooterLogo}>provided by</p>
              <img width="170px" className="responsive-img" src={footerLogoSrc} alt="footer-logo" />
            </LinkWrapper>

            <ul className={styles.socialLinkList}>
              <li>
                <a
                  className={styles.socialLinkItem}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/ctmalder/"
                >
                  <i className="icon-facebook" />
                </a>
              </li>
              <li>
                <a
                  className={styles.socialLinkItem}
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.instagram.com/ctm_alder_ag/?hl=de"
                >
                  <i className="icon-instagram" />
                </a>
              </li>
              <li>
                <a
                  className={styles.socialLinkItem}
                  rel="noopener noreferrer"
                  target="_blank"
                  href={t('info.emailhref')}
                >
                  <i className="icon-email" />
                </a>
              </li>
              <li>
                <a className={styles.socialLinkItem} rel="noopener noreferrer" href={t('info.telhref')}>
                  <i className="icon-whatsapp" />
                </a>
              </li>
              <li>
                <LinkWrapper to="/contacts" className={styles.socialLinkItem}>
                  <i className="icon-support" />
                </LinkWrapper>
              </li>
            </ul>
          </Col>
          <Col lg={7}>
            <div className={styles.footerTextWrapper}>
              <div className={styles.footerText}>
                <p className={styles.explanationText}>{t('footer.explanationText')}</p>
                <p className={styles.explanationText}>{t('footer.explanationText2')}</p>
              </div>
              <div className={styles.footerText}>
                <span className={styles.footerTextItem}>{t('footer.CTMAlderAG')}</span>
                <span className={styles.footerTextItem}>{t('footer.GlutzBlotzheimStrasse3')}</span>
                <span className={styles.footerTextItem}>{t('footer.CH4500Solothurn')}</span>
              </div>
              <div className={styles.footerText}>
                {t('info.tellabel')}
                {' · '}
                {t('footer.web')}
              </div>
              <div className={styles.footerCopyright}>
                {`© ${new Date().getFullYear()} `}
                copyright by Reist Telecom AG
                {' | '}
                <LinkWrapper className={styles.footerCopyrightLink} to="/term">
                  {t('footer.TOC')}
                </LinkWrapper>
                {' | '}
                <LinkWrapper className={styles.footerCopyrightLink} to="/imprint">
                  {t('footer.imprint')}
                </LinkWrapper>
                {' | '}
                <LinkWrapper className={styles.footerCopyrightLink} to="/asa">
                  {t('footer.asa')}
                </LinkWrapper>
                {process.env.REACT_APP_VERSION && ` | ${process.env.REACT_APP_VERSION}`}
              </div>
            </div>
          </Col>
          <Col lg={2}>
            <div className={styles.partnerLink}>
              <img src={logo} width={150} alt="logo" />
            </div>
          </Col>
          <Col sm={12}>
            <div className={styles.footerCopyrightMobile}>
              {`© ${new Date().getFullYear()} `}
              copyright by Reist Telecom AG
              {' | '}
              <LinkWrapper className={styles.footerCopyrightLink} to="/term">
                {t('footer.TOC')}
              </LinkWrapper>
              {' | '}
              <LinkWrapper className={styles.footerCopyrightLink} to="/imprint">
                {t('footer.imprint')}
              </LinkWrapper>
              {' | '}
              <LinkWrapper className={styles.footerCopyrightLink} to="/asa">
                {t('footer.asa')}
              </LinkWrapper>
              {process.env.REACT_APP_VERSION && ` | ${process.env.REACT_APP_VERSION}`}
            </div>
          </Col>
        </Row>
      </Container>
    </motion.footer>
  );
};

export default Footer;
